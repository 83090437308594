import React from "react";
import moment from "moment";
import { MapPinIcon } from "../../icons/Icons";
import Model from "@tripian/model";

type Props = {
  title: string;
  image: string | null;
  date: {
    startDate: string;
    when: string;
  };
  venue: Model.CityEventVenue;
};

const EventCard: React.FC<Props> = ({ title, image, date, venue }) => (
  <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 select-none h-40">
    <div className="relative h-full">
      {image ? (
        <img src={image} alt={title} className="w-full h-full object-cover" />
      ) : (
        <div className="w-full h-full bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0">{/* <CalendarIcon className="text-indigo-600 mb-12" /> */}</div>
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-black/0 p-4 flex flex-col justify-end">
        <div className="absolute top-3 right-3 bg-white/90 backdrop-blur-sm px-3 py-1.5 rounded-lg shadow-md">
          <span className="text-sm font-medium text-primary-color">{moment(date.startDate).format("MMM Do")}</span>
        </div>
        <h3 className="text-white font-semibold text-lg mb-1">{title}</h3>
        <div className="flex items-center gap-3 text-white/90">
          <div className="flex items-center gap-1">
            <div className="w-4 h-4 mr-1">
              <MapPinIcon className="w-4 h-4" />
            </div>

            <span className="text-sm">{venue.name}</span>
          </div>
          <div className="flex items-center gap-1">
            <span className="text-sm">⭐</span>
            <span className="pt-[1px]">{venue.rating}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default EventCard;
