import React, { useState } from "react";
import Model from "@tripian/model";
import EventCard from "../EventCard/EventCard";
import PlaceDetailsModal from "../PlaceDetailsModal/PlaceDetailsModal";

type Props = {
  events: Model.CityEvent[];
  t: (value: Model.TranslationKey) => string;
};

const EventList: React.FC<Props> = ({ events, t }) => {
  const [selectedEvent, setSelectedEvent] = useState<Model.CityEvent | null>(null);

  const handleEventSelect = (event: Model.CityEvent) => {
    if (selectedEvent?.id === event.id) {
      setSelectedEvent(null);
      return;
    }

    setSelectedEvent(event);
  };

  return (
    <div className="space-y-4">
      {events.map((event) => (
        <div
          key={event.id}
          onClick={() => handleEventSelect(event)}
          className={`cursor-pointer transition-all duration-300 ${selectedEvent?.id === event.id ? "transform scale-[1.02]" : "hover:scale-[1.01]"}`}
        >
          <EventCard title={event.title} image={event.image} date={event.date} venue={event.venue} />
        </div>
      ))}

      {selectedEvent && <PlaceDetailsModal isOpen={Boolean(selectedEvent)} onClose={() => setSelectedEvent(null)} event={selectedEvent} t={t} />}
    </div>
  );
};

export default EventList;
